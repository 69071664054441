import React from "react";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";
import { SkeletonDivAnimation } from "app/components/SkeletonAnimation";
import Icon, { CalendarAdd } from "app/components/Icon";
import Img from "app/components/Img";
import { H3, H5, A } from "app/components/Typography";
import { IS_MOBILE } from "constants/index";
import env from "helpers/env";

export const TodaysClassesSkeleton = () => {
  return (
    <Flex
      width={{ _: "100%", lg: "30%" }}
      bg="monochrome.7"
      position="relative"
      flexDirection="column"
      pt="24px"
    >
      <Div
        position="relative"
        width="100%"
        height="100%"
        p="16px 16px 24px"
        overflow="auto"
      >
        <Flex flexDirection="column" width="100%">
          <Flex flex={1} height="100%" flexDirection="column">
            <Div>
              <H3 color="white">Classes Taken Today</H3>
              <Div
                my={3}
                pb={2}
                borderBottom="1px solid"
                borderColor="monochrome.6"
              >
                <Img
                  src={`${env("PUBLIC_ASSET_PATH")}/icons/empty-task-cta.svg`}
                  width="100%"
                />
              </Div>
            </Div>
            <Flex justifyContent="space-between" mb={3}>
              <H3 color="white">Today&apos;s Classes</H3>
              <A color="white">Edit</A>
            </Flex>
          </Flex>
        </Flex>
        <Div position="relative" flex={1} width="100%" height="100%">
          <Div
            position={{ _: "relative", lg: "absolute" }}
            height="100%"
            width="100%"
          >
            <Div position="relative" height="100%">
              <Flex
                borderWidth="3px"
                borderStyle="solid"
                borderRadius="2px"
                mb="10px"
                width="100%"
              >
                <ClassRowSkeleton />
              </Flex>
              <Flex
                borderWidth="3px"
                borderStyle="solid"
                borderRadius="2px"
                mb="10px"
                width="100%"
              >
                <ClassRowSkeleton />
              </Flex>
              <Flex
                borderWidth="3px"
                borderStyle="solid"
                borderRadius="2px"
                mb="10px"
                width="100%"
              >
                <ClassRowSkeleton />
              </Flex>
              <Flex
                borderWidth="3px"
                borderStyle="solid"
                borderRadius="2px"
                mb="10px"
                width="100%"
              >
                <ClassRowSkeleton />
              </Flex>
              <Flex
                borderWidth="3px"
                borderStyle="solid"
                borderRadius="2px"
                mb="10px"
                width="100%"
              >
                <ClassRowSkeleton />
              </Flex>
            </Div>
          </Div>
        </Div>
      </Div>
      <Flex p={IS_MOBILE ? 0 : "12px 32px 32px"} flexDirection="column">
        <Flex
          m={IS_MOBILE ? 4 : 0}
          position="relative"
          border="2px dashed rgba(255, 255, 255, 0.3)"
          borderRadius="2px"
          alignItems="center"
          justifyContent="center"
          p={3}
        >
          <Icon width="24px" as={CalendarAdd} />
          <H5 m={0} ml={2} color="white">
            Add to schedule
          </H5>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ClassRowSkeleton = () => {
  return (
    <Flex
      width="100%"
      height="60px"
      borderRadius="2px"
      backgroundColor="monochrome.11"
      overflow="hidden"
      position="relative"
      zIndex="0"
    >
      <Div zIndex="1" position="absolute" width="100%" height="100%">
        <SkeletonDivAnimation/>
      </Div>
      <Flex
        width="100%"
        height="100%"
        overflow="hidden"
        position="absolute"
        zIndex="2"
      >
        <SkeletonText height="100%" width="87px" overflow="hidden">
          <SkeletonTextLoader width="500px" height="100%"/>
        </SkeletonText>
        <Flex flex={1} p={2} flexDirection="column" justifyContent="center" overflow="hidden">
          <SkeletonText
            height="12px"
            width="100%"
            mb="8px"
            >
            <SkeletonTextLoader width="500px" height="100%"/>
          </SkeletonText>
          <SkeletonText height="12px" width="50%">
            <SkeletonTextLoader width="500px" height="100%"/>
          </SkeletonText>
        </Flex>
      </Flex>
    </Flex>
  );
};
